import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  p {
    cursor: pointer;
  }

  > p:hover {
    & + div {
      visibility: visible;
      opacity: 1;
      transform: scale(1) translateY(0) translateX(-50%);
    }
  }
`;

export const Content = styled.div`
  position: absolute;
  left: 50%;
  top: 25px;
  transform: scale(0.95) translateY(16px) translateX(calc(-50% - 12px));
  border: var(--fd-border-widths-border-width-010) solid
    var(--fd-colors-border-default);
  padding: var(--fd-space-space-4);
  background-color: var(--fd-colors-background-surface);
  border-radius: var(--fd-radii-border-radius-020);
  box-shadow: var(--fd-shadows-elevations-bottom-mid);
  width: max-content;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 200ms ease,
    transform 200ms cubic-bezier(0.2, 0.8, 0.8, 1),
    visibility 200ms ease allow-discrete;
`;
