import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomepageConfigurableToggle,
  getNotificationPreferencesToggle
} from "@tvg/sh-lib-preferences/redux/selectors";
import { PreferencesTabEnum } from "@tvg/sh-lib-preferences/utils/types";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import { setSelectedTab } from "@tvg/sh-lib-preferences/redux/actions";
import { useAllowChoseBuiltInProfile } from "@urp/amplitude-experiments";

const notificationTab = {
  title: "Communications",
  qaLabel: "notifications-tab",
  value: PreferencesTabEnum.NOTIFICATIONS
};

const bettingTab = {
  title: "Betting",
  qaLabel: "betting-tab",
  value: PreferencesTabEnum.BETTING
};

const accountTab = {
  title: "Account",
  qaLabel: "account-tab",
  value: PreferencesTabEnum.ACCOUNT
};

const homepageConfigTab = {
  title: "Homepage Config",
  qaLabel: "homepage-config-tab",
  value: PreferencesTabEnum.HOMEPAGE_CONFIG
};

const useTabs = (selectedTab?: PreferencesTabEnum) => {
  const dispatch = useDispatch();
  const notificationPreferences = useSelector(getNotificationPreferencesToggle);
  const allowChoseProfile = useAllowChoseBuiltInProfile();
  const isHomepageConfigurable = useSelector(getHomepageConfigurableToggle);

  const getPrefTabs = () => {
    const tabs = isXSell() ? [] : [bettingTab, accountTab];

    if (notificationPreferences && !isXSell()) {
      tabs.unshift(notificationTab);
    }

    if (allowChoseProfile || isHomepageConfigurable) {
      tabs.push(homepageConfigTab);
    }

    return tabs;
  };

  const tabs = getPrefTabs();
  const defaultTab =
    selectedTab && tabs.some((tab) => tab.value === selectedTab)
      ? selectedTab
      : tabs[0].value;

  /* Set Betting tab selected on first render if notificationPreferences toggle is off */
  useEffect(() => {
    dispatch(setSelectedTab(defaultTab));
  }, []);

  useEffect(() => {
    if (selectedTab) {
      dispatch(setSelectedTab(defaultTab));
    }
  }, [defaultTab]);

  return {
    tabs,
    defaultTab,
    hasNotificationTab: notificationPreferences
  };
};

export default useTabs;
