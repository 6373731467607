import React from "react";
import { HomepageTemplate } from "@urp/homepage/src/types";
import { get } from "lodash";
import { Section } from "../Section";
import { Grid, GridSection } from "./styled-components";

interface Props {
  template: HomepageTemplate;
  isMobile?: boolean;
}

export function Layout({ template, isMobile }: Props) {
  return (
    <Grid>
      {isMobile ? (
        <Section components={get(template, "components", [])} />
      ) : (
        <>
          <GridSection gridArea="top">
            <Section title="Top" components={get(template, "top", [])} />
          </GridSection>
          <GridSection gridArea="main">
            <Section title="Main" components={get(template, "main", [])} />
          </GridSection>
          <GridSection gridArea="side">
            <Section title="Side" components={get(template, "side", [])} />
          </GridSection>
        </>
      )}
    </Grid>
  );
}
