import { breakpoints } from "@tvg/design-system";
import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--fd-colors-background-accent);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: var(--fd-space-space-4);
  border-radius: var(--fd-radii-border-radius-020);
  gap: 10px;
  height: 100%;
  width: 100%;

  @media screen and ${breakpoints.tablet.max.sm} {
    background-color: transparent;
    padding: var(--fd-space-space-3);
  }
`;
