import React, { useState } from "react";
import { get } from "lodash";
import type { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { closeConfirmationModalPrefAction } from "@tvg/sh-lib-preferences/redux/actions";
import type { ConfirmationModalType } from "@tvg/sh-lib-preferences/utils/types";
import { setBetConfirmationPreference } from "@tvg/mobile-account/src/actions";
import uam from "@tvg/api/uam";
import { Modal, Paragraph, useColorTokens } from "@tvg/design-system";
import { Button } from "@tvg/design-system/web";
import { muteUnmuteAllNotifications } from "@tvg/sh-lib-preferences/utils/notifications";
import { defaultBetPrefs } from "@tvg/sh-lib-preferences/utils/bettingUtils";
import { setDefaultBetPrefs } from "@tvg/shared-actions/UserActions";
import {
  getConfirmationModalInfo,
  getConfirmationModalPrefsCenterMessages
} from "@tvg/sh-lib-preferences/redux/selectors";
import { getAccountNumber } from "@urp/store-selectors";
import { Container } from "./styled-components";
import { onGenericGtmEvent } from "../../utils/gtm/generic";
import { confirmationModalGtmEvent } from "../../utils/gtm/confirmModal";

export const handleConfirmModalButtonClick = async ({
  dispatch,
  type,
  accountNumber,
  btnText,
  setIsLoading
}: {
  dispatch: Dispatch;
  type: ConfirmationModalType;
  accountNumber: string;
  btnText: string;
  setIsLoading: (value: boolean) => void;
}) => {
  if (type === "BETTING") {
    // Reset Confirmation betting modal
    await uam.setUserPref(accountNumber, "show_bet_confirm", "1");
    dispatch(setBetConfirmationPreference("1"));

    // Reset Betting preferences
    await uam.setUserPref(
      accountNumber,
      "bet_prefs",
      JSON.stringify(defaultBetPrefs)
    );

    dispatch(setDefaultBetPrefs(defaultBetPrefs));

    onGenericGtmEvent({
      gaEventAction: "CTA Clicked",
      gaEventLabel: btnText,
      module: "Reset Betting Preferences Modal"
    });
  }

  if (type === "NOTIFICATIONS") {
    await muteUnmuteAllNotifications(
      dispatch,
      accountNumber,
      true,
      setIsLoading
    );

    onGenericGtmEvent({
      gaEventAction: "CTA Clicked",
      gaEventLabel: btnText,
      module: "Mute All Notification Preferences Modal"
    });
  }

  dispatch(closeConfirmationModalPrefAction());
};

export const ConfirmModal = () => {
  const colorTokens = useColorTokens();
  const dispatch: Dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const accountNumber = useSelector(getAccountNumber);
  const confirmationModal = useSelector(getConfirmationModalInfo);
  const confirmModalCapi = useSelector(getConfirmationModalPrefsCenterMessages);

  const { isOpen, type } = confirmationModal;

  const isNotifications = type === "NOTIFICATIONS";
  const firstParagraph = get(confirmModalCapi, `[${type}]firstParagraph`, "");
  const secondParagraphText = get(
    confirmModalCapi,
    `[${type}]secondParagraph`,
    ""
  );

  return (
    <Modal
      isOpen={isOpen}
      title={get(confirmModalCapi, `[${type}]title`, "")}
      type="lightbox"
      onClose={() => {
        dispatch(closeConfirmationModalPrefAction());
        confirmationModalGtmEvent(false, isNotifications);
      }}
      qaLabel={`${type.toLowerCase()}-modal`}
    >
      <Container>
        <Paragraph
          color={colorTokens.content.subtle}
          qaLabel="confirm-modal-description"
        >
          {firstParagraph}
        </Paragraph>
        {isNotifications && secondParagraphText && (
          <Paragraph
            mt="space-1"
            color={colorTokens.content.subtle}
            qaLabel="second-confirm-modal-description"
          >
            {secondParagraphText}
          </Paragraph>
        )}
        {isNotifications && (
          <Paragraph
            mt="space-1"
            color={colorTokens.content.subtle}
            qaLabel="question-modal-description"
          >
            Do you want to proceed?
          </Paragraph>
        )}
        <Button
          mt="space-7"
          qaLabel="confirm-modal-button"
          variant="destructive"
          isStretched
          isLoading={isLoading}
          onClick={() =>
            handleConfirmModalButtonClick({
              dispatch,
              type,
              accountNumber,
              btnText: get(confirmModalCapi, `[${type}]btnText`, ""),
              setIsLoading
            })
          }
        >
          {get(confirmModalCapi, `[${type}]btnText`, "")}
        </Button>
        {isNotifications && (
          <Button
            mt="space-4"
            qaLabel="cancel-modal-button"
            variant="secondary"
            isStretched
            onClick={() => {
              dispatch(closeConfirmationModalPrefAction());
              onGenericGtmEvent({
                gaEventAction: "CTA Clicked",
                gaEventLabel: get(
                  confirmModalCapi,
                  `[${type}]secondBtnText`,
                  ""
                ),
                module: "Mute All Notification Preferences Modal"
              });
            }}
          >
            {get(confirmModalCapi, `[${type}]secondBtnText`, "")}
          </Button>
        )}
      </Container>
    </Modal>
  );
};

export default ConfirmModal;
