import mediator from "@tvg/mediator";

export const confirmationModalGtmEvent = (
  wasOpened: boolean,
  isNotifications?: boolean
) =>
  mediator.base.dispatch({
    type: "PREF_UPDATE",
    payload: {
      gaEventAction: `Modal ${wasOpened ? "Opened" : "Closed"}`,
      gaEventLabel: isNotifications
        ? wasOpened
          ? "Mute Notifications Preferences"
          : "Mute Notifications"
        : "Reset Betting Preferences",
      module: isNotifications
        ? wasOpened
          ? "Notifications"
          : "Mute All Notification Preferences Modal"
        : wasOpened
          ? "Betting"
          : "Reset Betting Preferences Modal"
    }
  });
