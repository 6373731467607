import React from "react";
import { Header } from "@tvg/design-system/web";
import { ComponentProps } from "@urp/homepage/src/types";
import { Container } from "./styled-components";
import { ComponentPreview } from "../ComponentPreview";

interface Props {
  title?: string;
  components: Array<ComponentProps>;
}

export function Section({ title, components }: Props) {
  return (
    <Container>
      {title && (
        <Header fontWeight="500" tag="h3" fontSize="m">
          {title}
        </Header>
      )}
      {components.map((comp) => (
        <ComponentPreview key={comp.component} {...comp} />
      ))}
    </Container>
  );
}
