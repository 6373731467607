import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getHomepageConfigUserPreferencesContent,
  getHomepageTemplateUserPrefDesktop,
  getHomepageTemplateUserPrefMobile,
  getShowHPTemplateSelector
} from "@tvg/sh-lib-preferences/redux/selectors";
import { getHomepageTemplates } from "@urp/homepage/src/store/selectors";
import { HomepageConfig as HomepagePreview } from "@urp/homepage-config";
import {
  setUserPrefsHomepageTemplateMobile,
  setUserPrefsHomepageTemplateDesktop
} from "@tvg/sh-lib-account-actions/src/actions/login";
import { isXSell } from "@tvg/sh-utils/isValidSessionByToken";
import {
  Header,
  Paragraph,
  useColorTokens,
  breakpoints,
  useMediaQuery,
  Checkbox,
  Switch,
  Loading,
  Button
} from "@tvg/design-system";
import { getAccountNumber } from "@urp/store-selectors";
import tvgConf from "@tvg/conf";
import { SectionConfigType, HomepageTemplate } from "@urp/homepage/src/types";
import { get } from "lodash";
import {
  Section,
  Content,
  InputSelectContainer,
  ToggleContainer,
  SwitchWrapper,
  PreviewSection,
  ApplyButtonContainer
} from "./styled-components";
import {
  getTemplateText,
  formatTemplate,
  setHPTemplateSelectorVisibility,
  updateUserTemplatePref
} from "./utils";
import Dropdown from "../Dropdown";
import { TabContainer } from "../PreferencesCenter/styled-components";

interface Props {
  hasAlert?: boolean;
}

const HomepageConfig = ({ hasAlert }: Props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>("Default");
  const dispatch = useDispatch();
  const colorTokens = useColorTokens();
  const isMobile =
    useMediaQuery(breakpoints.tablet.max.sm) || tvgConf().device === "mobile";
  const accountNumber = useSelector(getAccountNumber);
  const { title, description } = useSelector(
    getHomepageConfigUserPreferencesContent
  );
  const templatesConfig = useSelector(getHomepageTemplates);
  const prefShowHPTemplateSelector = useSelector(getShowHPTemplateSelector);
  const prefTemplate = useSelector(
    isMobile
      ? getHomepageTemplateUserPrefMobile
      : getHomepageTemplateUserPrefDesktop
  );
  const [isTemplateSelectorPrefLoading, setIsTemplateSelectorPrefLoading] =
    useState(false);
  const [isTemplatePrefLoading, setIsTemplatePrefLoading] = useState(false);

  useEffect(() => {
    if (prefTemplate) {
      setSelectedTemplate(prefTemplate);
    }
  }, [prefTemplate]);

  const handleOnChange = async (value: string) => {
    try {
      setIsTemplatePrefLoading(true);
      const result = await updateUserTemplatePref(
        accountNumber,
        value,
        isMobile
      );

      if (result.success) {
        if (isMobile) {
          dispatch(setUserPrefsHomepageTemplateMobile(value));
        } else {
          dispatch(setUserPrefsHomepageTemplateDesktop(value));
        }
        setSelectedTemplate(value);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error(
        "An unexpected error occurred during updating of template preference:",
        error
      );
    } finally {
      setIsTemplatePrefLoading(false);
    }
  };

  const handleHPTemplateSelectorVisibility = () => {
    setIsTemplateSelectorPrefLoading(true);
    setHPTemplateSelectorVisibility(
      accountNumber,
      !prefShowHPTemplateSelector,
      dispatch
    ).finally(() => setIsTemplateSelectorPrefLoading(false));
  };

  const renderToggle = isMobile ? (
    <ToggleContainer>
      <Paragraph qaLabel="show-template-selector-text">
        Don&apos;t show Profile Selector at Homepage
      </Paragraph>
      <SwitchWrapper>
        {isTemplateSelectorPrefLoading && (
          <Loading
            size="s"
            color={colorTokens.component.input.active}
            bgColor={colorTokens.component.input.border}
            qaLabel="switch-spinner"
          />
        )}
        <Switch
          isOn={!prefShowHPTemplateSelector}
          onPress={handleHPTemplateSelectorVisibility}
          qaLabel="show-template-selector-toggle"
        />
      </SwitchWrapper>
    </ToggleContainer>
  ) : (
    <Checkbox.Group
      selectedValues={
        !prefShowHPTemplateSelector ? ["hide-template-selector"] : []
      }
      qaLabel="show-template-selector-checkbox"
      onChange={handleHPTemplateSelectorVisibility}
    >
      <Checkbox
        value="hide-template-selector"
        label="Don't show Profile Selector at Homepage"
      />
    </Checkbox.Group>
  );

  return (
    <>
      <TabContainer
        data-qa-label="tab-children-container"
        hasAlert={!!hasAlert}
      >
        <Section data-qa-label="homepage-view-container">
          <Header tag="h3" qaLabel="homepage-view-title">
            {title}
          </Header>
          <Paragraph
            color={colorTokens.content.subtle}
            qaLabel="homepage-view-description"
          >
            {description}
          </Paragraph>
          <Content>
            <InputSelectContainer isMobile={isMobile}>
              <Dropdown
                qaLabel="homepage-view-dropdown"
                onChange={(template) => setSelectedTemplate(template)}
                selectedValue={getTemplateText(
                  templatesConfig,
                  selectedTemplate,
                  SectionConfigType.LABEL_NAME
                )}
                selectList={formatTemplate(templatesConfig)}
              />
            </InputSelectContainer>
          </Content>
          <Content>{!isXSell() && renderToggle}</Content>
        </Section>

        <PreviewSection>
          <HomepagePreview
            template={
              get(
                templatesConfig,
                selectedTemplate.toLowerCase()
              ) as HomepageTemplate
            }
          />
        </PreviewSection>
      </TabContainer>

      <ApplyButtonContainer>
        <Button
          variant="primary"
          onPress={() => handleOnChange(selectedTemplate)}
          qaLabel="homepage-view-apply-button"
          isStretched
          isDisabled={
            selectedTemplate.toLowerCase() === prefTemplate.toLowerCase()
          }
          isLoading={isTemplatePrefLoading}
        >
          Apply
        </Button>
      </ApplyButtonContainer>
    </>
  );
};

export default HomepageConfig;
