import { Paragraph } from "@tvg/design-system/web";
import React from "react";
import { Container, Content } from "./styled-components";

interface Props {
  children: React.ReactNode;
  text: string;
  qaLabel?: string;
}

export function Tooltip({ text, children, qaLabel }: Props) {
  return (
    <Container>
      <Paragraph
        qaLabel={qaLabel}
        color="var(--fd-colors-component-button-button-link-content-base)"
        fontFamily="bold"
        marginLeft="var(--fd-space-space-2)"
        lineHeight="1.45"
      >
        {text}
      </Paragraph>
      <Content>{children}</Content>
    </Container>
  );
}
