import React from "react";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import tvgConf from "@tvg/conf";
import { openConfirmationModalPrefAction } from "@tvg/sh-lib-preferences/redux/actions";
import { confirmationModalGtmEvent } from "@tvg/pref/src/utils/gtm/confirmModal";
import { AlertTypePref } from "@tvg/sh-lib-preferences/utils/types";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { AlertInLine, Button } from "@tvg/design-system/web";
import { DefaultContainer, BottomSpacer } from "./styled-components";
import { NotificationsPreferencesProps } from "./types";
import ToggleGroup, { ToggleGroupElement } from "../ToggleGroup";
import PreferencesRaceAlerts from "../RaceAlerts";
import DesktopLoading from "../Loading/Desktop";
import MobileLoading from "../Loading/Mobile";

export const handleAlertType = (type: AlertTypePref) => {
  switch (type) {
    case "error-single":
    case "error.mute":
    case "error.unmute":
      return "error";
    case "success.mute":
    case "success.unmute":
      return "success";
    default:
      return type;
  }
};

const NotificationsPreferences = ({
  notificationsCapi,
  alertsCapi,
  isDesktop,
  isNative,
  alertState,
  mtpRaceAlerts,
  isLoadingEmailOffers = false,
  isLoadingPushOffers = false,
  isLoadingEmailPromo = false,
  isLoadingPushPromo = false,
  isLoadingPushTalents = false,
  isLoadingEmailOthers = false,
  isLoadingPushOthers = false,
  isLoading,
  offersPush,
  setOffersPush,
  offersEmail,
  setOffersEmail,
  promoPush,
  setPromoPush,
  promoEmail,
  setPromoEmail,
  talentPicksPush,
  setTalentPicksPush,
  otherFanduelPush,
  setOtherFanduelPush,
  otherFanduelEmail,
  setOtherFanduelEmail,
  showMuteAllNotificationsPreferences,
  muteAllCommunications,
  handleResetNotifications,
  unmuteAllCommunications
}: NotificationsPreferencesProps) => {
  // Since the media query breakpoint is under cookie, there is a need to check device
  // For places that already are using Preferences
  const isMobile =
    useMediaQuery(breakpoints.tablet.max.sm) || tvgConf().device === "mobile";
  const dispatch = useDispatch();

  const renderRaceAlerts = () => (
    <PreferencesRaceAlerts
      qaLabel="RaceAlerts"
      description={get(notificationsCapi, "raceAlerts.description", "")}
      title={get(notificationsCapi, "raceAlerts.title")}
      footer={get(notificationsCapi, "raceAlerts.footer", ["", ""])}
      mtpRaceAlerts={mtpRaceAlerts}
    />
  );

  const renderOffersNews = () => {
    const toggles = [
      {
        name: get(notificationsCapi, "offersNews.label", ["", ""])[1],
        qaLabel: "offersNews-email",
        onClick: setOffersEmail,
        defaultValue: offersEmail,
        isLoading: isLoadingEmailOffers,
        isDisabled: !muteAllCommunications
      }
    ] as ToggleGroupElement[];

    if (!isDesktop && isNative) {
      toggles.unshift({
        name: get(notificationsCapi, "offersNews.label", ["", ""])[0],
        qaLabel: "offersNews-pushNotifications",
        onClick: setOffersPush,
        defaultValue: offersPush,
        isLoading: isLoadingPushOffers,
        isDisabled: !muteAllCommunications
      });
    }

    return (
      <ToggleGroup
        qaLabel="offersNews-container"
        title={get(notificationsCapi, "offersNews.title", "")}
        description={get(notificationsCapi, "offersNews.description", "")}
        toggles={toggles}
      />
    );
  };

  const renderPromotionActivity = () => {
    const toggles = [
      {
        name: get(notificationsCapi, "promotionActivity.label", ["", ""])[1],
        qaLabel: "promotionActivity-email",
        onClick: setPromoEmail,
        defaultValue: promoEmail,
        isLoading: isLoadingEmailPromo,
        isDisabled: !muteAllCommunications
      }
    ] as ToggleGroupElement[];

    if (!isDesktop && isNative) {
      toggles.unshift({
        name: get(notificationsCapi, "promotionActivity.label", ["", ""])[0],
        qaLabel: "promotionActivity-pushNotifications",
        onClick: setPromoPush,
        defaultValue: promoPush,
        isLoading: isLoadingPushPromo,
        isDisabled: !muteAllCommunications
      });
    }

    return (
      <ToggleGroup
        qaLabel="promotionActivity-container"
        title={get(notificationsCapi, "promotionActivity.title", "")}
        description={get(
          notificationsCapi,
          "promotionActivity.description",
          ""
        )}
        toggles={toggles}
      />
    );
  };

  const renderTalentPicks = () => {
    const toggles = [
      {
        name: get(notificationsCapi, "talentPicks.label", [""])[0],
        qaLabel: "talentPicks-pushNotifications",
        onClick: setTalentPicksPush,
        defaultValue: talentPicksPush,
        isLoading: isLoadingPushTalents,
        isDisabled: !muteAllCommunications
      }
    ] as ToggleGroupElement[];

    if (talentPicksPush) {
      toggles.push({
        name: get(notificationsCapi, "talentPicks.btnText", ""),
        qaLabel: "talentPicks-talentAlerts",
        onClick: () => {},
        btnType: true,
        url: "/preferences/talent-alerts"
      });
    }

    return (
      <ToggleGroup
        qaLabel="talentPicks-container"
        title={get(notificationsCapi, "talentPicks.title", "")}
        description={get(notificationsCapi, "talentPicks.description", "")}
        toggles={toggles}
      />
    );
  };

  const renderOther = () => {
    const toggles = [
      {
        name: get(notificationsCapi, "other.label", ["", ""])[1],
        qaLabel: "otherFanduelComunication-email",
        onClick: setOtherFanduelEmail,
        defaultValue: otherFanduelEmail,
        isLoading: isLoadingEmailOthers,
        isDisabled: !muteAllCommunications
      }
    ];

    if (!isDesktop && isNative) {
      toggles.unshift({
        name: get(notificationsCapi, "other.label", ["", ""])[0],
        qaLabel: "otherFanduelComunication-pushNotifications",
        onClick: setOtherFanduelPush,
        defaultValue: otherFanduelPush,
        isLoading: isLoadingPushOthers,
        isDisabled: !muteAllCommunications
      });
    }

    return (
      <ToggleGroup
        qaLabel="otherFanduelComunication-container"
        title={get(notificationsCapi, "other.title", "")}
        description={get(notificationsCapi, "other.description", "")}
        toggles={toggles}
      />
    );
  };

  const renderMuteBtn = () => (
    <BottomSpacer>
      <Button
        variant="tertiary"
        size="m"
        iconPosition="start"
        icon={muteAllCommunications ? "alertOff" : "alert"}
        isStretched
        onClick={
          muteAllCommunications
            ? handleResetNotifications
            : unmuteAllCommunications
        }
        qaLabel="muteAllNotifications-button"
      >
        {muteAllCommunications
          ? get(notificationsCapi, "muteBtn.mute", "")
          : get(notificationsCapi, "muteBtn.unmute", "")}
      </Button>
    </BottomSpacer>
  );

  if (isLoading) {
    return isMobile ? <MobileLoading /> : <DesktopLoading />;
  }

  return (
    <DefaultContainer>
      {get(alertState, "show", false) && (
        <AlertInLine
          variant={handleAlertType(alertState.type)}
          type={tvgConf().device === "desktop" ? "floating" : "fixed"}
          title={get(alertsCapi, `${alertState.type}.title`, "")}
          message={get(alertsCapi, `${alertState.type}.message`, "")}
          linkLabel={
            alertState.shouldShowButton &&
            get(alertsCapi, `${alertState.type}.hasButton`, "")
          }
          onLinkClick={() => {
            if (muteAllCommunications) {
              dispatch(openConfirmationModalPrefAction("NOTIFICATIONS"));
              confirmationModalGtmEvent(true);
            } else {
              unmuteAllCommunications();
            }
          }}
          qaLabel="generic-alert"
        />
      )}
      {!isDesktop && isNative && renderRaceAlerts()}
      {renderOffersNews()}
      {renderPromotionActivity()}
      {!isDesktop && isNative && renderTalentPicks()}
      {renderOther()}
      {showMuteAllNotificationsPreferences && renderMuteBtn()}
    </DefaultContainer>
  );
};

export default NotificationsPreferences;
