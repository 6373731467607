import React from "react";
import { HomepageTemplate } from "@urp/homepage/src/types";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { Layout } from "./components/Layout";

interface Props {
  template: HomepageTemplate;
}

export function HomepageConfig({ template }: Props) {
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);

  return <Layout template={template} isMobile={isMobile} />;
}
