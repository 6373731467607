import React from "react";
import { Sort, Option } from "@tvg/design-system";
import { DropdownProps } from "./types";

const Dropdown: React.FC<DropdownProps> = ({
  qaLabel,
  selectList,
  selectedValue,
  onChange
}) => (
  <Sort
    qaLabel={qaLabel}
    selectedValue={selectedValue}
    selectedLabel={selectedValue}
    selectedLabelColor="grey.900"
    selectedLabelFontFamily="regular"
    hasBorder
    justifyContent="space-between"
    size="l"
    width="100%"
    onChange={onChange}
  >
    {selectList.map(({ label, value, description }) => (
      <Option label={label} value={value} description={description} />
    ))}
  </Sort>
);

export default Dropdown;
