import { breakpoints } from "@tvg/design-system";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  border-radius: var(--fd-radii-border-radius-020);
  background-color: var(--fd-colors-background-surface);
  padding: var(--fd-space-space-4);
  display: flex;
  flex-flow: column nowrap;

  @media screen and ${breakpoints.tablet.max.sm} {
    padding: var(--fd-space-space-3);

    button {
      height: auto;

      &:active {
        background-color: transparent;
        border: none;
        color: var(--fd-colors-component-button-button-link-content-base);
        box-shadow: none;
      }
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  p {
    text-align: center;
    margin-top: var(--fd-space-space-4);
    margin-bottom: var(--fd-space-space-1);
  }

  @media screen and ${breakpoints.tablet.max.sm} {
    padding-bottom: max(env(safe-area-inset-bottom), 40px);
  }
`;

export const ImagePreview = styled.img`
  width: 100%;
  min-height: 100px;
  max-width: 500px;
`;
