import React from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import tvgConf from "@tvg/conf";
import {
  Header,
  InputNumber,
  InputSelect,
  Paragraph,
  useColorTokens,
  breakpoints,
  useMediaQuery
} from "@tvg/design-system";
import { Button, AlertInLine, Radio } from "@tvg/design-system/web";
import { getWagerPadToggle } from "@tvg/sh-lib-preferences/redux/selectors";

import {
  DefaultBetContainer,
  DefaultSection,
  BetGroupLine,
  WagerSelectContainer,
  CustomBetListSection
} from "./styled-components";
import ToggleGroup from "../ToggleGroup";
import { BettingPreferencesProps, KeyDown, WagerGroupsItem } from "./types";
import onChangeDefaultBetTypeSelected from "../../utils/gtm/defaultBetTypeSelected";
import defaultBetAmount from "../../utils/gtm/defaultBetAmount";
import customBetAmount from "../../utils/gtm/customBetAmount";

const BettingPreferences = ({
  defaultBetType,
  onSelectWagerType,
  onOptionChange,
  onKeyDown,
  onInputBlur,
  updateBetAmount,
  isMainWagerGroupsShown,
  wagerTypes,
  wagerGroups,
  updateShowBetConfirm,
  showBetConfirm,
  bettingCapi,
  hasDefaultBettingPreferences,
  handleResetPreferences,
  isLoadingBetConfirmation
}: BettingPreferencesProps) => {
  const colorTokens = useColorTokens();
  const isMobileDevice =
    useMediaQuery(breakpoints.tablet.max.sm) || tvgConf().device === "mobile";

  const wagerPadToogle = useSelector(getWagerPadToggle);

  const renderBetConfirmation = () => (
    <ToggleGroup
      qaLabel="bet-confirmation-container"
      title={bettingCapi.betConfirmation.title}
      description={bettingCapi.betConfirmation.description}
      toggles={[
        {
          name: bettingCapi.betConfirmation.label,
          onClick: updateShowBetConfirm,
          defaultValue: showBetConfirm === "1",
          qaLabel: "bet-confirmation",
          isLoading: isLoadingBetConfirmation
        }
      ]}
    />
  );

  const mappedTypes = wagerTypes.map((wager) => ({
    label: wager.type.name,
    value: wager.type.id
  }));

  const renderDefaultBetType = () => {
    const descriptionCapiKey = isMobileDevice
      ? "betType.descriptionMobile"
      : `betType.${wagerPadToogle ? "descriptionWagerPad" : "description"}`;

    return (
      <DefaultSection
        isMobileDevice={isMobileDevice}
        data-qa-label="bet-preference-type-section"
      >
        <Header tag="h3" qaLabel="bet-preference-type-label">
          {get(bettingCapi, "betType.title", "")}
        </Header>

        <Paragraph
          color={colorTokens.content.subtle}
          qaLabel="bet-preference-type-description"
        >
          {get(bettingCapi, descriptionCapiKey, "")}
        </Paragraph>

        <WagerSelectContainer isMobileDevice={isMobileDevice}>
          <InputSelect
            qaLabel="bet-preference-type-wager"
            selectedValue={+defaultBetType}
            onChange={(val) => {
              const text = wagerTypes.reduce(
                (acc, currentValue) =>
                  currentValue.type.id === +val ? currentValue.type.name : acc,
                ""
              );
              onChangeDefaultBetTypeSelected({ value: text });
              onSelectWagerType(val as unknown as string, text);
            }}
            selectList={mappedTypes}
          />
        </WagerSelectContainer>
      </DefaultSection>
    );
  };

  const renderCustomBetAmountList = (wagerList: Array<WagerGroupsItem>) => (
    <CustomBetListSection data-qa-label="bet-preference-custom-bet-list-section">
      <AlertInLine
        message={bettingCapi.betAmount.message}
        qaLabel="bet-preference-custom-bet-list-alert"
        variant="informational"
      />
      {wagerList.map((wagerItem: WagerGroupsItem) => {
        let title =
          wagerItem.title.charAt(0).toUpperCase() + wagerItem.title.slice(1);
        if (wagerItem.title === "pick") {
          title = `${title} (all pick bets)`;
        }

        return (
          <BetGroupLine
            key={wagerItem.title}
            data-qa-label={`bet-preference-custom-bet-list-bet-group-${wagerItem.title}`}
          >
            <Header
              tag="h3"
              fontFamily="medium"
              fontSize="s"
              color={colorTokens.content.default}
              qaLabel="bet-preference-custom-bet-list-bet-group-title"
            >
              {title}
            </Header>
            <InputNumber
              qaLabel={`wager-amount-${wagerItem.group}`}
              placeholder={bettingCapi.betAmount.placeholder}
              value={wagerItem.value}
              onChangeText={(value) => {
                updateBetAmount(value, wagerItem);
              }}
              onBlur={() => {
                onInputBlur(wagerItem);
                customBetAmount({
                  fieldInput: wagerItem.value,
                  betType: wagerItem.title
                });
              }}
              start={
                <>
                  {wagerItem.value !== "" && (
                    <Paragraph
                      color={colorTokens.content.subtle}
                      ml="space-4"
                      qaLabel="bet-preference-custom-bet-list-symbol"
                    >
                      $
                    </Paragraph>
                  )}
                </>
              }
              onKeyPress={(e) => onKeyDown(e as unknown as KeyDown)}
              hasDecimalPlaces
              width={212}
            />
          </BetGroupLine>
        );
      })}
    </CustomBetListSection>
  );

  const renderBetAmountOptions = () => (
    <DefaultSection
      isMobileDevice={isMobileDevice}
      data-qa-label="bet-preference-amount-options-section"
    >
      <Header
        tag="h3"
        qaLabel="bet-preference-amount-options-label"
        mb="space-5"
      >
        {get(bettingCapi, "betAmount.title", "")}
      </Header>
      <Radio.Group
        qaLabel="bet-preference-amount-container-radio-group"
        selectedValue={
          isMainWagerGroupsShown ? "useCustomBetAmount" : "useMinimumBetAmount"
        }
        onChange={(val) => {
          onOptionChange(val);
          defaultBetAmount({ value: val });
        }}
      >
        <Radio
          value="useMinimumBetAmount"
          label={get(bettingCapi, "betAmount.label[0]", "")}
          qaLabel="bet-preference-amount-section-checkbox-use-minimum-amount"
        />
        <Radio
          value="useCustomBetAmount"
          label={get(bettingCapi, "betAmount.label[1]", "")}
          qaLabel="bet-preference-amount-section-checkbox-use-custom-amount"
        />
      </Radio.Group>

      {isMainWagerGroupsShown && renderCustomBetAmountList(wagerGroups)}
      {!hasDefaultBettingPreferences && (
        <Button
          variant="tertiary"
          onClick={handleResetPreferences}
          qaLabel="betting-reset-button"
          isStretched
          px="space-5"
          mt="space-6"
        >
          {bettingCapi.resetButton}
        </Button>
      )}
    </DefaultSection>
  );

  return (
    <DefaultBetContainer data-qa-label="bet-preference-wrap">
      {renderBetConfirmation()}
      {renderDefaultBetType()}
      {renderBetAmountOptions()}
    </DefaultBetContainer>
  );
};

export default BettingPreferences;
