import React, { useState } from "react";
import { ComponentProps } from "@urp/homepage/src/types";
import { Button, Header, Paragraph } from "@tvg/design-system/web";
import { breakpoints, Modal, useMediaQuery } from "@tvg/design-system";
import {
  Container,
  TitleWrapper,
  TooltipContainer,
  ImagePreview
} from "./styled-components";
import { Tooltip } from "../Tooltip";

export function ComponentPreview(props: ComponentProps) {
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const hasPreviewImage = props.previewImage?.filename;

  const renderDescription = () =>
    props.description && (
      <Paragraph
        qaLabel="component-preview-description"
        marginTop="var(--fd-space-space-1)"
        color="var(--fd-colors-content-subtle)"
      >
        {props.description}
      </Paragraph>
    );

  const renderImagePreview = () => {
    const { filename, alt } = props.previewImage ?? {};

    return hasPreviewImage ? (
      <TooltipContainer>
        <ImagePreview
          src={filename}
          alt={alt || `${props.title} component preview`}
        />
        {renderDescription()}
      </TooltipContainer>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Container
        data-qa-label={`component-preview-${props.component.toLowerCase()}`}
      >
        <TitleWrapper>
          <Header fontWeight="500" tag="h3" qaLabel="component-preview-title">
            {props.title || props.component}
          </Header>
          {hasPreviewImage &&
            (isMobile ? (
              <Button
                variant="tertiary"
                qaLabel="component-preview-label"
                marginLeft="var(--fd-space-space-2)"
                onClick={() => setIsPreviewModalOpen(true)}
                size="s"
                padding="var(--fd-space-space-0)"
              >
                Preview
              </Button>
            ) : (
              <Tooltip qaLabel="component-preview-label" text="Preview">
                {renderImagePreview()}
              </Tooltip>
            ))}
        </TitleWrapper>
        {renderDescription()}
      </Container>

      {isMobile && (
        <Modal
          isOpen={isPreviewModalOpen}
          title={props.title}
          subtitle="Preview"
          type="flexible"
          qaLabel="component-preview-modal"
          onClose={() => setIsPreviewModalOpen(false)}
          onOverlayClick={() => setIsPreviewModalOpen(false)}
        >
          {renderImagePreview()}
        </Modal>
      )}
    </>
  );
}
