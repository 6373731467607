import { breakpoints } from "@tvg/design-system";
import styled from "styled-components";

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-areas:
    "top top"
    "main side";
  gap: var(--fd-space-space-4);

  @media screen and ${breakpoints.tablet.max.sm} {
    grid-template-columns: 1fr;
    grid-template-areas: none;
  }
`;

export const GridSection = styled.div<{ gridArea: string }>`
  grid-area: ${({ gridArea }) => gridArea};
`;
